import { oneOf } from 'prop-types'

const FormSetup = ({ method = 'POST' }) => {
  const { key, value } = getAuthToken()
  const requiresMethodOverride = method !== 'POST' && method !== 'GET'
  return (
    <>
      <input type='hidden' name='utf-8' value='✓' />
      <input type='hidden' name={key} value={value} />
      {requiresMethodOverride && (
        <input type='hidden' name='_method' value={method} />
      )}
    </>
  )
}

FormSetup.propTypes = {
  method: oneOf(['GET', 'POST', 'PATCH', 'PUT', 'DELETE']),
}

export default FormSetup

function getAuthToken() {
  const key = document
    .querySelector('meta[name="csrf-param"]')
    .getAttribute('content')
  const value = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content')
  return { key, value }
}
