import { any, bool, func, string } from 'prop-types'
import AriaModal from 'react-aria-modal'
import { useEffect } from 'react'

const Dialog = ({
  children,
  className,
  initialFocus,
  onCancel,
  opened,
  title,
}) => {
  // Manage custom class on html tag to prevent auto-scrolling that would
  // produce an unexpected animation due to default `scroll-behavior: smooth`
  // style
  useEffect(() => {
    if (opened) {
      document.documentElement.classList.add('no-scroll')
      return () => document.documentElement.classList.remove('no-scroll')
    }
  })

  if (!opened) {
    return null
  }

  return (
    <AriaModal
      dialogClass={className}
      getApplicationNode={getApplicationNode}
      includeDefaultStyles={false}
      initialFocus={initialFocus}
      focusDialog={initialFocus === undefined}
      onExit={onCancel}
      titleId='modal-title'
      titleText={title}
      underlayClass='modal-overlay'
      scrollDisabled={true}
    >
      <div role='document' className={`${className}__wrapper`}>
        <button
          type='button'
          className={`${className}-close`}
          onClick={onCancel}
        >
          <span className={`${className}-close__text`}>Fermer</span>
        </button>
        <div className={`${className}__content`}>
          <h1 id='modal-title' className={`${className}-title`}>
            {title}
          </h1>
          <div id='js-modal-content'>{children}</div>
        </div>
      </div>
    </AriaModal>
  )

  function getApplicationNode() {
    return document.getElementById('js-modal-page')
  }
}

Dialog.propTypes = {
  children: any.isRequired,
  className: string.isRequired,
  initialFocus: string,
  onCancel: func.isRequired,
  opened: bool.isRequired,
  title: string.isRequired,
}

export default Dialog
