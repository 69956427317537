import { useEffect, useRef } from 'react'

import { formatOptionActionLabel } from '../../../../../shared/js/options-utils'
import { ProductOptionWithNamePropTypes } from '../../../../../shared/js/prop-types'

const Checkbox = ({
  details,
  disabled,
  id,
  label,
  name,
  onChange,
  onSubmit,
  triggerFocus,
  values: [value],
  valid,
}) => {
  const cbLabel = formatOptionActionLabel(label, { ...value })
  const descriptionId = details ? `description-input-${id}` : null
  const ariaDescribedBy = details ? { 'aria-describedby': descriptionId } : {}
  const fieldRef = useRef(null)

  // When autofocus is expected, trigger it,
  // then move smoothly into view
  useEffect(() => {
    if (fieldRef.current && triggerFocus) {
      fieldRef.current.scrollIntoView({ behavior: 'smooth' })
      fieldRef.current.focus({ preventScroll: true })
    }
  }, [triggerFocus])

  return (
    <>
      <div className='c-option__checkbox'>
        <input
          defaultChecked={value.selected}
          disabled={disabled}
          id='gift'
          name={name}
          onChange={handleChange}
          ref={fieldRef}
          type='checkbox'
          {...ariaDescribedBy}
        />
        <label
          className='c-label c-label--checkbox c-label--checkbox c-label--highlight'
          htmlFor='gift'
        >
          {cbLabel}
        </label>
      </div>

      {!valid && (
        <p className='c-message c-message--error'>
          Vous devez cocher cette option pour continuer
        </p>
      )}
      {details && (
        <p id={descriptionId} className='c-message c-message--help'>
          {details}
        </p>
      )}
    </>
  )

  function handleChange() {
    onChange(id, [{ ...value, selected: !value.selected }], onSubmit)
  }
}

Checkbox.propTypes = ProductOptionWithNamePropTypes

export default Checkbox
