import { render } from 'react-dom'

import ErrorBoundary from './components/ErrorBoundary'

// A unified way of mounting a component tree inside a DOM container element,
// dynamically grabbing its view-injected props (server-rendered), plus custom
// extra props when needed (e.g. related-products HTML).
export default function mountReactContainer({
  id,
  selector = `#${id}`,
  component: Component,
  ...extraProps
}) {
  const containers = document.querySelectorAll(selector)

  if (containers.length === 0) {
    return
  }

  for (const container of containers) {
    const attrs = container.getAttribute('data-props') || '{}'
    const { contentId, ...props } = JSON.parse(attrs) || {}
    if (!props) {
      return
    }

    // Load HTML content as HTML
    if (contentId) {
      const content = document.getElementById(contentId)
      if (content) {
        props.html = content.innerHTML
      }
    }

    render(
      <ErrorBoundary>
        <Component {...props} {...extraProps} />
      </ErrorBoundary>,
      container
    )
  }
}
