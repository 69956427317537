import { useEffect, useRef, useState } from 'react'

import { adjustLabelForOptionality } from './Option'
import { ProductOptionWithNamePropTypes } from '../../../../../shared/js/prop-types'
import { REGEX_BASE64_IMAGE } from '../../../../../shared/js/options-utils'

// Limit file size to 10Mb
const MAX_FILE_SIZE = 1024 * 1000 * 10

export default function ImageInput({
  details,
  disabled,
  id,
  label,
  name,
  onChange,
  required,
  triggerFocus,
  values: [value],
  valid,
}) {
  const inputId = `file-${id}`
  const descriptionId = details ? `description-input-${id}` : null
  const ariaDescribedBy = details ? { 'aria-describedby': descriptionId } : {}
  const fieldRef = useRef(null)
  const [error, setError] = useState('')

  // When autofocus is expected, trigger it,
  // then move smoothly into view
  useEffect(() => {
    if (fieldRef.current && triggerFocus) {
      fieldRef.current.scrollIntoView({ behavior: 'smooth' })
      fieldRef.current.focus({ preventScroll: true })
    }
  }, [triggerFocus])

  if (!required) {
    label = adjustLabelForOptionality(label)
  }

  return (
    <div className='u-mb-2'>
      <label
        className='c-label c-label--image'
        htmlFor={inputId}
        {...ariaDescribedBy}
      >
        {label}
      </label>
      <input
        accept='image/png, image/jpeg'
        aria-required={required}
        aria-invalid={!valid}
        disabled={disabled}
        id={inputId}
        name={name}
        onChange={handleChange}
        ref={fieldRef}
        type='file'
      />
      {!valid && (
        <p className='c-message c-message--error'>
          Vous devez fournir une image ou une photo
        </p>
      )}
      {error.length > 0 && (
        <p className='c-message c-message--error'>{error}</p>
      )}
      {details && (
        <p id={descriptionId} className='c-message c-message--help'>
          {details}
        </p>
      )}
    </div>
  )

  function handleChange({
    target: {
      files: [file],
    },
  }) {
    if (MAX_FILE_SIZE < file.size) {
      // File is too heavy, inform customer
      setError('Désolé, votre image pèse trop lourd')
    } else {
      const reader = new FileReader()
      // Read file content as base64
      reader.onload = () => {
        const text = reader.result
        if (REGEX_BASE64_IMAGE.test(text)) {
          onChange(id, [{ ...value, text, selected: text.length > 0 }])
        } else {
          setError(
            'Désolé, le format que vous avez choisi n’est pas pris en compte'
          )
        }
      }
      reader.onerror = (error) => {
        setError('Un problème est survenu lors du chargement de l’image')
        console.error(error)
      }
      reader.readAsDataURL(file)
    }
  }
}

ImageInput.propTypes = ProductOptionWithNamePropTypes
