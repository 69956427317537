import { func } from 'prop-types'
import { useState } from 'react'

import Dialog from '../../shared/Dialog'
import { formatOptionActionLabel } from '../../../../../shared/js/options-utils'
import { ProductOptionWithNamePropTypes } from '../../../../../shared/js/prop-types'

const Button = ({
  children,
  details,
  disabled,
  id,
  label,
  name,
  onCancel,
  onChange,
  // Optionnal function that should be called on option valiation (ie. modal
  // dismiss or validation)
  onSubmit,
  values: [value],
  valid,
}) => {
  const [active, setActive] = useState(false)

  const ctaLabel = formatOptionActionLabel(label, {
    ...value,
    updateMode: value?.selected,
  })

  return (
    <>
      <button
        className='c-btn c-btn--secondary c-btn--sticky-engraving'
        disabled={disabled}
        onClick={toggleDialog}
      >
        {ctaLabel}
      </button>

      {children && (
        <Dialog
          className='right-action-modal'
          onCancel={handleDialogClose}
          opened={active}
          title={label}
        >
          {details && (
            <span className='c-contextual-information u-txt-center'>
              {details}
            </span>
          )}
          {children}

          <div className='right-action-modal__btn'>
            <button
              className='c-btn c-btn--fill'
              type='reset'
              onClick={handleCancel}
            >
              Annuler
            </button>
            <button
              className='c-btn c-btn--primary'
              onClick={handleValidate}
              disabled={!valid}
            >
              Appliquer
            </button>
          </div>
        </Dialog>
      )}
    </>
  )

  function toggleDialog(event) {
    event.preventDefault()
    if (!active) {
      onChange(id, [{ ...value, selected: !active }])
    }
    setActive(!active)
  }

  // When values are valid run option storage
  function handleDialogClose(event) {
    valid ? handleValidate(event) : handleCancel()
  }

  // L'appel vide à `onChange` déclenche la réinitialisation de l’état de
  // l’option
  function handleCancel() {
    setActive(!active)
    onCancel(id)
    onSubmit && onSubmit()
  }

  function handleValidate(event) {
    toggleDialog(event)
    onSubmit && onSubmit()
  }
}

Button.propTypes = {
  ...ProductOptionWithNamePropTypes,
  onCancel: func.isRequired,
  onSubmit: func,
}

export default Button
