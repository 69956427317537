import Appsignal from '@appsignal/javascript'
import { ErrorBoundary as AppSignalErrorBoundary } from '@appsignal/react'
import { func, node } from 'prop-types'
import React from 'react'

// Extracted from `config/appsignal.yml`
const appsignal = new Appsignal({ key: '4288c7a2-a2e0-4a7a-a744-6d23e3b6277f' })

const DefaultFallbackComponent = () => (
  <div className='c-message c-message--error'>
    Oops ! Quelque chose s’est mal passé et certains éléments n’ont pas pu être
    chargés. Veuillez réessayer dans quelques instants.
  </div>
)

const ErrorBoundary = ({
  children,
  fallBackComponent = DefaultFallbackComponent,
}) => {
  return (
    <AppSignalErrorBoundary
      fallback={() => React.createElement(fallBackComponent)}
      instance={appsignal}
    >
      {children}
    </AppSignalErrorBoundary>
  )
}

ErrorBoundary.propTypes = {
  children: node.isRequired,
  fallBackComponent: func,
}

export default ErrorBoundary
