import { array, oneOfType, string } from 'prop-types'

// Internal component for a single option label(s)
const OptionsSummaryLabel = ({ title, label }) => {
  // No label
  if (!label) {
    return <strong>{title}</strong>
  }

  return (
    <>
      <strong>{title} : </strong>
      {Array.isArray(label)
        ? label
            .map((subLabel, index) =>
              Array.isArray(subLabel) ? subLabel[1] : subLabel
            )
            .join(' - ')
        : label}
    </>
  )
}

OptionsSummaryLabel.propTypes = {
  title: string.isRequired,
  label: oneOfType([array, string]),
}

export default OptionsSummaryLabel
